import { ChangeDetectionStrategy, Component, inject, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { TravelerOperatorConfig } from '../../shared/config/traveler-operator-config.model';
import { travelerOperatorConfigToken, travelerTranslationToken } from '../../shared/config/tokens';
import { TravelerTranslationModel } from '../../shared/models/translation.model';

export interface InfoDialogData {
  title: string;
}

@Component({
  selector: 'traveler-info-dialog',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatDividerModule],
  template: `
    <mat-dialog-content class="max-h-[80vh]">
      <h1 class="mb-2 text-black">{{ translation().info.about }} {{ data.title }}</h1>
      <div>
        {{ translation().info.content }}
      </div>
      <div class="mt-2">
        <a
          href="https://www.nobina.com/sv/vara-erbjudanden/nobina-bus/vara-erbjudanden-och-tjanster/ersattningstrafik/"
          target="_blank"
          [style.color]="appConfig.primaryColor"
          class="underline"
          >{{ translation().info.readMore }}</a
        >.
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-raised-button mat-dialog-close>{{ translation().info.close }}</button>
    </mat-dialog-actions>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoDialogComponent {
  data: InfoDialogData = inject(MAT_DIALOG_DATA);
  appConfig = inject<TravelerOperatorConfig>(travelerOperatorConfigToken);
  translation = inject<WritableSignal<TravelerTranslationModel>>(travelerTranslationToken);
}
