import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiError } from '../error-handling/api-error';
import { inject, Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { appConfigToken, libTranslationConfigToken } from '../utils/injection-tokens';
import { ConfigModelWeb } from '../assets/config/config.model';
import { LibTranslation } from '../i18n/lib-translation.model';
import { translationStringSubstitution } from '../utils/translation-util';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  msalService = inject(MsalService);
  appConfig = inject<ConfigModelWeb>(appConfigToken);
  translation = inject<LibTranslation>(libTranslationConfigToken);
  locale = this.appConfig.region.language;

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let headers = req.headers.set('Accept-Language', this.locale);

    if (!req.headers.has('Content-Type')) {
      headers = headers.set('Content-Type', 'application/json');
    }

    const requestWithHeaders = req.clone({ headers });

    return next.handle(requestWithHeaders).pipe(
      catchError((httpError: HttpErrorResponse) => {
        //log error to application insights

        switch (httpError.status) {
          case 0:
            return throwError(() => new ApiError(this.translation.api.error.noConnection));
          case 200:
            return next.handle(requestWithHeaders);
          case 401:
            return this._refreshAndRetry(next, requestWithHeaders).pipe(
              catchError((retryError: HttpErrorResponse) => {
                return throwError(
                  () =>
                    new ApiError(
                      translationStringSubstitution(
                        this.translation.api.error.unauthorized,
                        retryError.status.toString(),
                      ),
                    ),
                );
              }),
            );
          case 403:
            return throwError(
              () =>
                new ApiError(
                  translationStringSubstitution(this.translation.api.error.forbidden, httpError.status.toString()),
                ),
            );
          default:
            return throwError(() =>
              httpError.error
                ? new ApiError(httpError.error.errorMessage ?? httpError.error.detail, httpError.error.operationId)
                : new ApiError(this.translation.api.error.unknown),
            );
        }
      }),
    );
  }

  private _refreshAndRetry(
    next: HttpHandler,
    requestWithHeaders: HttpRequest<unknown>,
  ): Observable<HttpEvent<unknown>> {
    return this.msalService
      .acquireTokenSilent({
        forceRefresh: true,
        scopes: this._getScopeForRequest(requestWithHeaders),
        account: this.msalService.instance.getActiveAccount()!,
      })
      .pipe(
        switchMap((result: AuthenticationResult) => {
          const clonedRequest = this._updateHeadersForRetry(requestWithHeaders, result.accessToken);
          return next.handle(clonedRequest);
        }),
      );
  }

  private _updateHeadersForRetry(request: HttpRequest<unknown>, accessToken: string): HttpRequest<unknown> {
    let newHeaders = new HttpHeaders();
    request.headers.keys().forEach((key) => {
      const val = request.headers.get(key);
      if (key === 'Authorization') {
        newHeaders = newHeaders.set(key, `Bearer ${accessToken}`);
      } else {
        newHeaders = newHeaders.set(key, val!);
      }
    });
    return request.clone({ headers: newHeaders });
  }

  private _getScopeForRequest(request: HttpRequest<unknown>): string[] {
    if (request.url.includes(this.appConfig.apiUrlConfig.apiUrl)) {
      return this.appConfig.oauth.mainServiceScopes;
    } else if (request.url.includes(this.appConfig.apiUrlConfig.garageUrl)) {
      return this.appConfig.oauth.garageServiceScopes;
    } else if (request.url.includes(this.appConfig.apiUrlConfig.outcomeUrl)) {
      return this.appConfig.oauth.outcomeServiceScopes;
    } else if (request.url.includes(this.appConfig.apiUrlConfig.positionUrl)) {
      return [];
      //return this.appConfig.oauth.iotFunctionsServiceScopes;
    } else {
      return [];
    }
  }
}
