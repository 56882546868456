import { Language, TravelerTranslationModel } from '../models/translation.model';

export const translationNB: TravelerTranslationModel = {
  language: Language.NB,
  searchPlaceholder: 'Søk stoppested',
  today: 'I dag',
  tomorrow: 'I morgen',
  stop: {
    route: 'Linje',
    replacedTrain: 'Erstatter tog',
    towards: 'Mot',
    departs: 'Avgang',
    stop: 'Stoppested',
    location: 'Sted',
    active: 'Aktiv',
    reason: 'Årsak',
    vehicleIdentifier: 'Kjøretøy',
    disembarkingOnly: 'Kun avstigende',
  },
  info: {
    about: 'Om',
    content: 'Denne nettsiden viser informasjon om erstatningstjenester som drives av Nobina på vegne av togselskapet ditt. Målet er at du som reisende skal få relevant informasjon om pågående erstatningstrafikk, uansett om det er planlagt eller haster. Siden viser all bekreftet trafikk for de neste 24 timene. All informasjonen du finner på denne siden er også fullt tilgjengelig via API for integrering i reiseapper, reiseplanleggere og andre tjenester. Vi er Nobina, det største kollektivselskapet i Norden. Hos oss jobber 13 000 mennesker hver dag for å finne løsninger på ditt hverdagspuslespill og på samfunnets store utfordringer.',
    readMore: 'Les mer på nobina.com',
    close: 'Lukk',
  },
  error: {
    affectedStopsMissing: 'Det er ingen erstatningstrafikk for øyeblikket',
    stopMissing: 'Informasjon for holdeplassen mangler',
    tripMissing: 'Informasjon for turen mangler',
    close: 'Lukk',
  },
  seo: {
    tagline: 'Hvor er min erstatningsbuss?',
    description:
      'Ersatningstrafikk kjørt av Nobina på vegne av ditt togselskap. Målet er at du som reisende skal få relevant informasjon om pågående erstatningstrafikk.',
  },
};
