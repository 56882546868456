import { LibTranslation } from './lib-translation.model';

export const libTranslationNB: LibTranslation = {
  common: {
    errorMessage: 'En feil har oppstått',
    button: {
      close: 'Lukk',
      cancel: 'Avbryt',
      apply: 'Tilpass',
      assign: 'Tildel',
      save: 'Lagre',
      confirm: 'Bekreft',
    },
  },
  toolbar: {
    navigation: {
      order: 'Bestillinger',
      history: 'Historie',
      home: 'Hjem',
      outcome: 'Oppfølging',
      planning: 'Planlegging',
      administration: 'Administrasjon',
      routes: 'Linjer',
    },
    userMenu: {
      operatorBtn: 'Velg operatør',
      subheadingRole: 'Min rolle:',
      subheadingRoles: 'Mine roller:',
      subheadingOrg: 'Mine organisasjoner:',
      title: 'Profil',
      logout: 'Logg ut',
      manageGarage: 'Håndter min garasjen',
      manageOperatorUsers: 'Håndtere brukere',
      userManual: 'Manual',
    },
    notifications: {
      title: 'Varsler',
      markAsRead: 'Merk som lest',
      seeAll: 'Se alt',
      noNotifications: 'Ingen varsler',
      goTo: 'Gå til',
      alert: {
        openDriveOrder: 'Åpne kjøreordre',
        reconnecting: 'Tapt kontakt med sanntidsoppdateringer. Prøver å gjenopprette tilkobling til serveren.',
        reconnected: 'Gjenoppretting av tilkobling til serveren var vellykket.',
      },
      driveOrderInquiryCreatedNotification: {
        title: 'Ny innkommende forespørsel',
        severalDriveOrdersMessage: 'Du har {0} nye forespørsler',
        oneDriveOrderMessage: 'Du har {0} ny forespørsel',
      },
      driveOrderAssignedNotification: {
        title: 'Ny tildeling',
        severalDriveOrdersMessage: 'Du har blitt tildelt {0} nye kjøreordrer',
        oneDriveOrderMessage: 'Du har blitt tildelt {0} ny kjøreordre',
      },
    },
    search: {
      select: {
        orderId: 'Bestilling',
        driveOrderId: 'Kjøreordre',
        tripId: 'Tur',
        unboundOrderId: 'Friordre',
      },
      alertMsg: {
        notANumberMessage: 'Ugyldig nummer. Angi kun tall.',
        notFoundMessage: 'Det finnes ingen bestilling i systemet som matcher søkt nummer.',
      },
    },
  },
  vehicleTypes: {
    bigBus: 'Stor buss',
    smallBus: 'Minibuss',
    doubleDecker: 'Dobbeltdekker',
    taxi: 'Taxi',
    bigTaxi: 'Maxitaxi',
    wheelchairTaxi: 'Rullestoltaxi',
    standbyBus: 'Beredskapsbuss',
    standbyWheelchairTaxi: 'Beredskaps maxitaxi',
    customerHost: 'Kundeveileder',
    busCoordinator: 'Busskoordinator',
    car: 'Personbil',
  },
  order: {
    trip: {
      cancelReason1: 'Feilbestilling',
      cancelReason2: 'Avbestilt av bestiller',
      cancelReason3: 'Endring av bestilling',
      cancelReason4: 'Ikke tilsatt',
      cancelReason5: 'Ikke gjennomført',
      cancelReason6: 'Annen årsak',
      cancelReason7: 'Erstattet',
    },
    tripStatus: {
      upcoming: 'Kommende',
      ongoing: 'Pågående',
      completed: 'Fullført',
      cancelled: 'Avbestilt',
    },
    orderType: {
      urgent: 'Akutt',
      unplanned: 'Uplanlagt',
      planned: 'Planlagt',
      unbound: 'Friordre',
    },
  },
  search: {
    filter: {
      startDateTime: 'Startdato',
      endDateTime: 'Sluttdato',
      hours: 'Timer',
      minutes: 'Minutter',
      resetTime: 'Tilbakestille tid',
    },
  },
  api: {
    error: {
      unknown: 'Ukjent feil',
      noConnection: 'Ingen kontakt med serveren',
      forbidden: 'Behørighet mangler (status {0})',
      unauthorized: 'Behørighet mangler (status {0})',
      errorId: 'Feil-ID',
    },
    success: {
      toggleGarageSuccess: 'Status på garasje endret',
      createUserSuccess: 'Brukere skapt',
      deleteUserSuccess: 'Brukere tatt bort',
      deleteUsersSuccess: 'Brukere tatt bort',
      updateUserSuccess: 'Brukere oppdatert',
      resetPasswordSuccess: 'Passordet har blitt nullstilt',
      assignSubcontractorToDriveOrderSuccess: 'Underleverandør tildelt',
      assignVehicleToDriveOrderSuccess: 'Kjøretøy tildelt',
      assignDriverToDriveOrderSuccess: 'Sjåfør tildelt',
      changeStartTimeSuccess: 'Starttid endret',
      saveTrainNumberSuccess: 'Tognummer har blitt lagret',
      savePassengerCountSuccess: 'Antall passasjerer har blitt lagret',
      saveCommentSuccess: 'Kommentar har blitt lagret',
      cancelTripsSuccess: {
        oneTrip: 'Turen er kansellert',
        twoOrMoreTrips: 'Turene er kansellert',
        allOrRestOfTrips: 'Kjøreordren er kansellert',
      },
      replaceTripsSuccess: {
        oneTrip: 'Turen har blitt erstattet',
        twoOrMoreTrips: 'Turene har blitt erstattet',
        allOrRestOfTrips: 'Kjøreordren har blitt erstattet',
      },
      splitTripsSuccess: {
        oneTrip: 'Turen har blitt delt',
        twoOrMoreTrips: 'Turene har blitt delt',
        allOrRestOfTrips: 'Kjøreordren har blitt delt',
      },
      duplicateTripsSuccess: {
        oneTrip: 'Turen har blitt forsterket',
        twoOrMoreTrips: 'Turene har blitt forsterket',
        allOrRestOfTrips: 'Kjøreordren har blitt forsterket',
      },
      changeVehicleTypeSuccess: 'Kjøretøytype har blitt endret',
    },
  },
};
