import { ConfigModelFull, EnvironmentType } from './config.model';

export const appConfigDev: ConfigModelFull = {
  oauth: {
    mainServiceScopes: ['openid', 'https://tbumsst.onmicrosoft.com/55b5a525-48f6-4cf9-92db-61c37aa1627e/Tbums.Main'],
    garageServiceScopes: [
      'openid',
      'https://tbumsst.onmicrosoft.com/064b4d2c-627e-49a3-8fe7-0b957526480c/Tbums.Garage',
    ],
    outcomeServiceScopes: [
      'openid',
      'https://tbumsst.onmicrosoft.com/119604fa-b3ce-4cdc-a188-130df9955527/Tbums.Outcome',
    ],
    routeServiceScopes: ['openid', 'https://tbumsst.onmicrosoft.com/0a7d99f7-4150-412a-89c1-3a0ba2641281/Tbums.Route'],
    authorityDomain: 'tbumsst.b2clogin.com',
    authority: 'https://tbumsst.b2clogin.com/tbumsst.onmicrosoft.com/B2C_1A_NOBINAFALLBACKPOLICY',
    clientId: '277ae760-aadf-4676-8b42-3f7ce85b33fd',
    tenantId: '4341c565-4ac4-4ef0-bbd4-8041d91cf7a6',
    redirectUrl: '/',
  },
  apiUrlConfig: {
    apiUrl: 'https://mainservice-tbums-st.azurewebsites.net/api/',
    apiVersionMain: 'v2',
    outcomeUrl: 'https://outcomeservice-tbums-st.azurewebsites.net/api/',
    apiVersionOutcome: 'v1',
    positionUrl: 'https://iotfunctions-tbums-st.azurewebsites.net/api',
    garageUrl: 'https://garageservice-tbums-st.azurewebsites.net/api/',
    apiVersionGarage: 'v1',
    routeUrl: 'https://routeservice-tbums-st.azurewebsites.net/api/',
    apiVersionRoute: 'v1',
  },
  appInsights: {
    instrumentationKey: 'bfcfb64c-e534-4acd-b54c-09ebffd4026c',
  },
  se: {
    language: 'sv',
    locale: 'sv_SE',
    enableBilling: true,
    enableUnboundOrder: true,
  },
  no: {
    language: 'nb',
    locale: 'no_NO',
    enableBilling: true,
    enableUnboundOrder: true,
  },
  da: {
    language: 'da',
    locale: 'da_DK',
    enableBilling: true,
    enableUnboundOrder: true,
  },
  fi: {
    language: 'fi',
    locale: 'fi_FI',
    enableBilling: true,
    enableUnboundOrder: true,
  },
  environmentType: EnvironmentType.Local,
  isPlanningEnabled: true,
  isPlanningCalendarEnabled: true,
  isPlanningUEActionsEnabled: true,
  isApplicationInsightsEnabled: true,
  isRouteWebEnabled: true,
  isSearchEnabled: true,
  isVehicleManagementEnabled: true,
  isOperatorUserManagementEnabled: true,
  mapboxKey: 'pk.eyJ1IjoiaW5mb2Vyc2F0dGEiLCJhIjoiY20zZWY3aGt5MGVidDJucXhqOThyZzIwdiJ9.nBgaP4diwILCEP29m_qoaw',
};
