import { Language, TravelerTranslationModel } from '../models/translation.model';

export const translationFI: TravelerTranslationModel = {
  language: Language.FI,
  searchPlaceholder: 'Haku pysähtyy',
  today: 'Tänään',
  tomorrow: 'Huomenna',
  stop: {
    route: 'Linja',
    replacedTrain: 'Korvattu Juna',
    towards: 'Vastaan',
    departs: 'Lähteä',
    stop: 'Pysäkki',
    location: 'Sijainti',
    active: 'Aktiivinen',
    reason: 'Syy',
    vehicleIdentifier: 'Ajoneuvo',
    disembarkingOnly: 'Vain poistuville',
  },
  info: {
    about: 'Siitä',
    content: 'Tämä verkkosivusto näyttää tietoja Nobinan junayhtiösi puolesta tarjoamista korvauspalveluista. Tavoitteena on, että sinä matkustajana saat oleellista tietoa meneillään olevasta korvaava liikenteestä riippumatta siitä, onko se suunniteltu vai kiireellinen. Sivu näyttää kaiken vahvistetun liikenteen seuraavan 24 tunnin ajalta. Kaikki tältä sivulta löytyvä tieto on myös täysin saatavilla API:n kautta integroitavaksi matkasovelluksiin, matkasuunnittelijoihin ja muihin palveluihin. Olemme Nobina, Pohjoismaiden suurin joukkoliikenneyritys. Meillä 13 000 ihmistä työskentelee päivittäin löytääkseen ratkaisuja jokapäiväiseen pulmaan ja yhteiskunnan suuriin haasteisiin.',
    readMore: 'Lue lisää osoitteessa nobina.com',
    close: 'Kiinni',
  },
  error: {
    affectedStopsMissing: 'Korvaavaa liikennettä ei ole tällä hetkellä',
    stopMissing: 'Pysähdyksen tiedot puuttuvat',
    tripMissing: 'Matkan tiedot puuttuvat',
    close: 'Sulje',
  },
  seo: {
    tagline: 'Missä on korvausbussini?',
    description:
      'Nobinan liikennöimää korvausliikennettä junayhtiösi toimeksiannosta. Tavoitteena on, että matkustajana saat ajantasaista tietoa käynnissä olevasta korvausliikenteestä.',
  },
};
