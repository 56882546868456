import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  signal,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { first, map, startWith } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { AutoCompleteModel } from './auto-complete.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'tbums-lib-auto-complete',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    A11yModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
  ],
  template: ` <form>
    <mat-form-field
      subscriptSizing="dynamic"
      cdkOverlayOrigin
      #originOverlay="cdkOverlayOrigin"
      class="relative w-full"
      [ngClass]="{ 'relative z-[10000]': showPanel() }"
    >
      <mat-label>{{ fieldLabel }}</mat-label>
      <input
        class="text-ellipsis"
        #inputElement
        matInput
        [formControl]="searchFormControl"
        (focus)="open()"
        aria-autocomplete="none"
      />
      @if (showClearBtn && selectedVal()) {
        <button mat-icon-button matSuffix (click)="selectItem(null); $event.stopPropagation()">
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="originOverlay"
      [cdkConnectedOverlayOpen]="showPanel()"
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      (backdropClick)="backdropClicked()"
      [cdkConnectedOverlayHasBackdrop]="true"
    >
      <div
        #list
        [ngStyle]="{ width: componentWidth() + 'px' }"
        class="max-h-96 overflow-x-hidden overflow-y-visible rounded-b bg-white shadow-2xl"
      >
        <div class="p-1" [ngClass]="{ hidden: !filterValues || filterValues.length === 0 }">
          <mat-radio-group [formControl]="subFilterFormControl">
            @for (option of filterValues; track option) {
              <mat-radio-button [value]="$index" class="text-sm" [ngClass]="{ 'mr-5': $index === 0 }"
                >{{ option }}
              </mat-radio-button>
            }
          </mat-radio-group>
        </div>
        <ul>
          <cdk-virtual-scroll-viewport itemSize="48" class="h-72 w-full">
            <li
              [ngClass]="{
                'bg-gray-100': activeOptionIndex() === i
              }"
              (click)="selectItem(item)"
              class="flex-column flex h-[48px] items-center justify-between border-b-[1px] border-b-gray-100 p-2 hover:bg-gray-100"
              *cdkVirtualFor="let item of filteredList(); let i = index"
            >
              <div class="flex flex-col">
                <span [id]="item.id" class="flex-grow text-ellipsis text-xs" #listItem>{{ item.label }}</span>
                <span *ngIf="showSecondaryText" class="text-gray1 flex-grow text-xs">{{ item.secondaryLabel }}</span>
              </div>
              <mat-icon class="w-6" color="primary" [ngClass]="{ invisible: item.id !== selectedVal()?.id }"
                >done
              </mat-icon>
            </li>
          </cdk-virtual-scroll-viewport>
        </ul>
      </div>
    </ng-template>
  </form>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoCompleteComponent implements OnInit, OnChanges {
  @Input({ required: true }) fieldLabel = '';
  @Input({ required: true }) selected: AutoCompleteModel | null = null;
  @Input() showSecondaryText = false;
  @Input() showSelection = true;
  @Input() isDisabled = false;
  @Input() showClearBtn = false;
  @Input() filterValues: string[] = [];
  @Output() selectedChange = new EventEmitter<string | number | null>();
  @Output() filterChange = new EventEmitter<number>();

  @ViewChildren('listItem', { read: ElementRef })
  private _menuItems!: QueryList<ElementRef>;

  @ViewChild('inputElement') private _input!: ElementRef<HTMLInputElement>;

  @ViewChild(CdkVirtualScrollViewport) virtualScroll!: CdkVirtualScrollViewport;

  @ViewChild(CdkConnectedOverlay, { static: true })
  private _connectedOverlay!: CdkConnectedOverlay;

  @ViewChild(CdkOverlayOrigin, { static: true, read: ElementRef })
  private _trigger!: ElementRef<HTMLElement>;

  componentWidth = computed(() => {
    if (this.showPanel()) {
      return this._trigger.nativeElement.getBoundingClientRect().width;
    } else {
      return 200;
    }
  });

  optionList: AutoCompleteModel[] = [];

  filteredList = signal<AutoCompleteModel[]>([]);
  searchFormControl = new FormControl('');

  subFilterFormControl = new FormControl(0);

  showPanel = signal<boolean>(false);
  activeOptionIndex = signal<number>(0);
  selectedVal = signal<AutoCompleteModel | null>(null);

  private _destryRef = inject(DestroyRef);

  private _focusEffect = effect(() => {
    if (this.activeOptionIndex() && this._menuItems && this._menuItems.length > 0) {
      const item = this._menuItems.find(
        (element: ElementRef) => element.nativeElement.id == this.filteredList()[this.activeOptionIndex()].id,
      );
      if (item) {
        item.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  });

  @Input({ required: true }) set list(list: AutoCompleteModel[] | null) {
    if (this.optionList.length != list?.length && !this.selected) {
      this.searchFormControl.setValue('');
    }

    if (list) {
      this.optionList = list;
      this.filterItems('');
    }
  }

  ngOnInit(): void {
    this.listenToKeyEvents();
    this.updatedSelected();

    this.searchFormControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => {
          return this.filterItems(value ?? '');
        }),
      )
      .pipe(takeUntilDestroyed(this._destryRef))
      .subscribe();

    this.subFilterFormControl.setValue(0);
    this.subFilterFormControl.valueChanges.pipe(takeUntilDestroyed(this._destryRef)).subscribe((selection) => {
      if (selection !== null) {
        this.filterChange.emit(parseInt(selection.toString()));
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selected']) {
      this.updatedSelected();
    }
    if (changes['isDisabled']) {
      this.updateDisabled();
    }
  }

  updateDisabled() {
    if (this.isDisabled) {
      this.searchFormControl.disable();
    } else {
      this.searchFormControl.enable();
    }
  }

  updatedSelected() {
    if (this.selected) {
      this.selectedVal.set(this.selected);
      if (this.showSelection) {
        this.searchFormControl.setValue(this.selected.label);
      }
    } else {
      this.selectedVal.set(null);
      this.searchFormControl.setValue('');
    }
  }

  listenToKeyEvents() {
    this._connectedOverlay.overlayKeydown.pipe(takeUntilDestroyed(this._destryRef)).subscribe((event) => {
      if (event.key === 'ArrowUp' && this.activeOptionIndex() > 0) {
        this.activeOptionIndex.set(this.activeOptionIndex() - 1);
      } else if (event.key === 'ArrowDown' && this.activeOptionIndex() < this.filteredList().length - 1) {
        this.activeOptionIndex.set(this.activeOptionIndex() + 1);
      } else if (event.key === 'Enter') {
        this.selectItem(this.filteredList()[this.activeOptionIndex()]);
      } else if (event.key === 'Tab') {
        this.showPanel.set(false);
      }
      event.stopPropagation();
    });
  }

  open() {
    if (!this.isDisabled) {
      this.showPanel.set(true);
      this.searchFormControl.setValue('');
      this.activeOptionIndex.set(0);
    }
  }

  backdropClicked() {
    this.searchFormControl.setValue(this.selected?.label ?? '');
    this.showPanel.set(false);
  }

  selectItem(item: AutoCompleteModel | null) {
    if (item === null) {
      this.selectedVal.set(null);
      this.searchFormControl.setValue('');
      this.selectedChange.emit(null);
      this.showPanel.set(false);
    } else {
      this.showPanel.set(false);
      if (this.showSelection) {
        this.selectedVal.set(item);
        this.searchFormControl.setValue(item.label);
      }
      this.selectedChange.emit(item.id);
      this._input.nativeElement.blur();
    }
  }

  filterItems(filter: string) {
    this.filteredList.set(
      this.optionList.filter((item) => {
        return item.label.toLowerCase().includes(filter.toLowerCase());
      }),
    );
    if (this.virtualScroll) {
      this.virtualScroll.scrollToIndex(0);
    }

    this.activeOptionIndex.set(0);
  }
}
