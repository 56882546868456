import { Language, TravelerTranslationModel } from '../models/translation.model';

export const translationDK: TravelerTranslationModel = {
  language: Language.DK,
  searchPlaceholder: 'Søgning stop',
  today: 'I dag',
  tomorrow: 'I morgen',
  stop: {
    route: 'Rute',
    replacedTrain: 'Erstattet tog',
    towards: 'Mod',
    departs: 'Afgår',
    stop: 'Stop',
    location: 'Placering',
    active: 'Aktiv',
    reason: 'Årsag',
    vehicleIdentifier: 'Tybe af køretøj',
    disembarkingOnly: 'Kun afstigende',
  },
  info: {
    about: 'Hvis',
    content:
      'Denne hjemmeside viser information om erstatningstjenester, der drives af Nobina på vegne af dit togselskab. Målet er, at du som rejsende får relevant information om igangværende erstatningstrafik, uanset om det er planlagt eller akut. Siden viser al bekræftet trafik for de næste 24 timer. Al den information, du kan finde på denne side, er også fuldt tilgængelig via API til integration i rejseapps, rejseplanlæggere og andre tjenester. Vi er Nobina, det største offentlige transportselskab i Norden. Hos os arbejder 13.000 mennesker hver dag for at finde løsninger på din hverdags gåde og på samfundets store udfordringer.',
    readMore: 'Læs mere på nobina.com',
    close: 'Tæt',
  },
  error: {
    affectedStopsMissing: 'Det er ingen erstatningstrafik i øjeblikket',
    stopMissing: 'Oplysninger til stoppestedet mangler',
    tripMissing: 'Oplysninger til tur mangler',
    close: 'Luk',
  },
  seo: {
    tagline: 'Hvor er min erstatningsbus?',
    description:
      'Ersatningstrafik kørt af Nobina på vegne af dit togselskab. Målet er, at du som rejsende får relevant information om igangværende erstatningstrafik.',
  },
};
