import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinksModel } from './links.model';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'tbums-lib-navigation',
  standalone: true,
  imports: [CommonModule, MatButtonModule, RouterLink, RouterLinkActive],
  template: ` <div class="flex flex-col gap-1 lg:flex-row">
    @for (link of links; track link; let i = $index) {
      <a
        [href]="link.address"
        *ngIf="link.isExternal"
        mat-flat-button
        color="primary"
        class="w-full no-underline sm:w-auto h-8 transparent-buttons"
        routerLinkActive="link-active"
        >{{ link.label }}</a
      >
      <a
        *ngIf="!link.isExternal"
        [routerLink]="link.address"
        mat-flat-button
        class="w-full no-underline sm:w-auto h-8 transparent-buttons"
        routerLinkActive="link-active"
        color="primary"
        >{{ link.label }}</a
      >
    }
  </div>`,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  @Input() links!: LinksModel[];
}
