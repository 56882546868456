import { LibTranslation } from './lib-translation.model';

export const libTranslationSV: LibTranslation = {
  common: {
    errorMessage: 'Ett fel har uppstått',
    button: {
      close: 'Stäng',
      cancel: 'Avbryt',
      apply: 'Tillämpa',
      assign: 'Tilldela',
      save: 'Spara',
      confirm: 'Bekräfta',
    },
  },
  toolbar: {
    navigation: {
      order: 'Beställningar',
      history: 'Historik',
      home: 'Hem',
      outcome: 'Uppföljning',
      planning: 'Planering',
      administration: 'Administration',
      routes: 'Linjer',
    },
    userMenu: {
      operatorBtn: 'Välj operatör',
      subheadingRole: 'Min roll:',
      subheadingRoles: 'Mina roller:',
      subheadingOrg: 'Mina organisationer:',
      title: 'Profil',
      logout: 'Logga ut',
      manageGarage: 'Hantera mitt garage',
      manageOperatorUsers: 'Hantera användare',
      userManual: 'Manual',
    },
    notifications: {
      title: 'Notiser',
      markAsRead: 'Markera som lästa',
      seeAll: 'Se alla',
      noNotifications: 'Inga notiser',
      goTo: 'Gå till',
      alert: {
        openDriveOrder: 'Öppna körorder',
        reconnecting: 'Tappat kontakt med realtidsuppdateringar. Försöker återansluta till servern.',
        reconnected: 'Återanslutning till servern lyckades.',
      },
      driveOrderInquiryCreatedNotification: {
        title: 'Ny inkommen förfrågan',
        severalDriveOrdersMessage: 'Du har {0} nya förfrågningar',
        oneDriveOrderMessage: 'Du har {0} ny förfrågan',
      },
      driveOrderAssignedNotification: {
        title: 'Ny tilldelning',
        severalDriveOrdersMessage: 'Du har blivit tilldelad {0} nya körordrar',
        oneDriveOrderMessage: 'Du har blivit tilldelad {0} ny körorder',
      },
    },
    search: {
      select: {
        orderId: 'Beställning',
        driveOrderId: 'Körorder',
        tripId: 'Tur',
        unboundOrderId: 'Friorder',
      },
      alertMsg: {
        notANumberMessage: 'Ogiltigt nummer. Ange endast siffror.',
        notFoundMessage: 'Det finns ingen beställning i systemet som matchar sökt nummer.',
      },
    },
  },
  vehicleTypes: {
    bigBus: 'Stor buss',
    smallBus: 'Liten buss',
    doubleDecker: 'Dubbeldäckare',
    taxi: 'Taxi',
    bigTaxi: 'Stor taxi',
    wheelchairTaxi: 'Rullstolstaxi',
    standbyBus: 'Stand-by buss',
    standbyWheelchairTaxi: 'Stand-by rullstolstaxi',
    customerHost: 'Kundvärd',
    busCoordinator: 'Busskoordinator',
    car: 'Personbil',
  },
  order: {
    trip: {
      cancelReason1: 'Felbeställning',
      cancelReason2: 'Avbeställd av beställare',
      cancelReason3: 'Ändring av beställning',
      cancelReason4: 'Ej tillsatt',
      cancelReason5: 'Ej utförd',
      cancelReason6: 'Annan orsak',
      cancelReason7: 'Ersatt',
    },
    orderType: {
      urgent: 'Akut',
      unplanned: 'Oplanerad',
      planned: 'Planerad',
      unbound: 'Friorder',
    },
    tripStatus: {
      upcoming: 'Kommande',
      ongoing: 'Pågående',
      completed: 'Avslutad',
      cancelled: 'Avbeställd',
    },
  },
  search: {
    filter: {
      startDateTime: 'Startdatum',
      endDateTime: 'Slutdatum',
      hours: 'Timmar',
      minutes: 'Minuter',
      resetTime: 'Återställ tiden',
    },
  },
  api: {
    error: {
      forbidden: 'Behörighet saknas (status {0})',
      noConnection: 'Ingen kontakt med servern',
      unauthorized: 'Behörighet saknas (status {0})',
      unknown: 'Okänt fel',
      errorId: 'Fel-ID',
    },
    success: {
      toggleGarageSuccess: 'Status på garaget ändrad',
      createUserSuccess: 'Användare skapad',
      deleteUserSuccess: 'Användare borttagen',
      deleteUsersSuccess: 'Användarna borttagna',
      updateUserSuccess: 'Användare uppdaterad',
      resetPasswordSuccess: 'Lösenordet har återställts',
      assignSubcontractorToDriveOrderSuccess: 'Underentreprenör tilldelad',
      assignVehicleToDriveOrderSuccess: 'Fordon tillsatt',
      assignDriverToDriveOrderSuccess: 'Förare tillsatt',
      changeStartTimeSuccess: 'Starttiden har ändrats',
      saveTrainNumberSuccess: 'Tågnummer har sparats',
      savePassengerCountSuccess: 'Antal passagerare har sparats',
      saveCommentSuccess: 'Kommentar har sparats',
      cancelTripsSuccess: {
        oneTrip: 'Turen har ställts in',
        twoOrMoreTrips: 'Turerna har ställts in',
        allOrRestOfTrips: 'Körordern har ställts in',
      },
      replaceTripsSuccess: {
        oneTrip: 'Turen har ersatts',
        twoOrMoreTrips: 'Turerna har ersatts',
        allOrRestOfTrips: 'Körordern har ersatts',
      },
      splitTripsSuccess: {
        oneTrip: 'Turen har delats',
        twoOrMoreTrips: 'Turerna har delats',
        allOrRestOfTrips: 'Körordern har delats',
      },
      duplicateTripsSuccess: {
        oneTrip: 'Turen har förstärkts',
        twoOrMoreTrips: 'Turerna har förstärkts',
        allOrRestOfTrips: 'Körordern har förstärkts',
      },
      changeVehicleTypeSuccess: 'Fordonstyp har ändrats',
    },
  },
};
