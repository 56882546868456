import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { appConfigToken, ConfigModelWeb, Operator } from '@tbums.mono.web/tbums-lib';

@Injectable({
  providedIn: 'root',
})
export class OperatorService {
  private _appConfig = inject<ConfigModelWeb>(appConfigToken);
  private baseUrl = this._appConfig.apiUrlConfig.apiUrl + this._appConfig.apiUrlConfig.apiVersionMain + '/operators';

  constructor(private http: HttpClient) {}

  getOperators(filterByUser: boolean = false, includeInactive: boolean = false): Observable<Operator[]> {
    return this.http.get<Operator[]>(
      this.baseUrl + `?FilterByUserSelection=${filterByUser}` + `&IncludeInactive=${includeInactive}`,
    );
  }
}
