import { ConfigModelFull, ConfigModelWeb } from '../assets/config/config.model';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import { ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { appConfigToken, libTranslationConfigToken, translationConfigToken } from '../utils/injection-tokens';
import { nb, sv } from 'date-fns/locale';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { APP_BASE_HREF } from '@angular/common';
import { provideRouter, Routes, withComponentInputBinding } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import { msalGuardConfigFactory, msalInstanceFactory, msalInterceptorConfigFactory } from './b2c-config-functions';
import { CustomHttpInterceptor } from '../interceptors/custom-http-interceptor';
import { ApplicationInsightsService } from '../services/application-insights.service';
import { GlobalErrorHandlerService } from '../services/global-error-handler.service';
import { LibTranslation } from '../i18n/lib-translation.model';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

export const fetchAppConfig = async (bootstrapAppFunction: (config: ConfigModelFull) => void) => {
  const url =
    window.location.hostname === 'localhost'
      ? './assets/generated-config/app-config-local.json'
      : './assets/generated-config/app-config.json';
  await fetch(url, { cache: 'no-store' })
    .then((response) => response.json())
    .then((config: ConfigModelFull) => bootstrapAppFunction(config));
};

export const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0,
  },
};

export const getRegionConfig = (config: ConfigModelFull): ConfigModelWeb => {
  const webConfig: ConfigModelWeb = {
    oauth: config.oauth,
    apiUrlConfig: config.apiUrlConfig,
    appInsights: config.appInsights,
    region: config.se,
    environmentType: config.environmentType,
    isPlanningEnabled: config.isPlanningEnabled,
    isPlanningCalendarEnabled: config.isPlanningCalendarEnabled,
    isPlanningUEActionsEnabled: config.isPlanningUEActionsEnabled,
    isApplicationInsightsEnabled: config.isApplicationInsightsEnabled,
    isRouteWebEnabled: config.isRouteWebEnabled,
    isSearchEnabled: config.isSearchEnabled,
    isVehicleManagementEnabled: config.isVehicleManagementEnabled,
    isOperatorUserManagementEnabled: config.isOperatorUserManagementEnabled,
    mapboxKey: config.mapboxKey,
  };

  if (window.location.hostname !== 'localhost') {
    const parts = window.location.hostname.split('.');
    let selectedRegion;
    switch (parts[parts.length - 1]) {
      case 'se':
        selectedRegion = config.se;
        break;
      case 'fi':
        selectedRegion = config.fi;
        break;
      case 'no':
        selectedRegion = config.no;
        break;
      case 'dk':
        selectedRegion = config.da;
        break;
      default:
        selectedRegion = config.se;
        break;
    }
    webConfig.region = selectedRegion;
  }

  return webConfig;
};

export function getProviders(
  appConfig: ConfigModelWeb,
  translation: unknown,
  libTranslation: LibTranslation,
  appRoutes: Routes,
  baseRef: string,
) {
  return [
    {
      provide: LOCALE_ID,
      useFactory: (config: ConfigModelWeb) => () => config.region.locale,
      deps: [appConfigToken],
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: globalRippleConfig,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: appConfig.region.locale.includes('sv') ? sv : nb,
    },
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    {
      provide: appConfigToken,
      useValue: appConfig,
    },
    {
      provide: translationConfigToken,
      useValue: translation,
    },
    {
      provide: libTranslationConfigToken,
      useValue: libTranslation,
    },
    {
      provide: APP_BASE_HREF,
      useValue: baseRef,
    },
    provideRouter(appRoutes, withComponentInputBinding()),
    importProvidersFrom(BrowserAnimationsModule, HttpClientModule, MatDialogModule),
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
      deps: [appConfigToken],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory,
      deps: [appConfigToken],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
      deps: [appConfigToken],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    ApplicationInsightsService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
  ];
}
